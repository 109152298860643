export default {
    data: {
        days: {
            sat: {status: 0, shifts: []},
            sun: {status: 0, shifts: []},
            mon: {status: 0, shifts: []},
            tue: {status: 0, shifts: []},
            wed: {status: 0, shifts: []},
            thur: {status: 0, shifts: []},
            fri: {status: 0, shifts: []},
        },
        status: 1
    }
}
