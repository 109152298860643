export default {

    async get({commit}, data = '') {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .get(`notifications?per_page=${data}`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
    async limit({commit},) {
        await this._vm.$http
            .get(`notifications?per_page=5`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setLimit', response.data.data);
                }
            }).catch((error) => {
                throw error;
            });
    },

    async markAsRead({commit}) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('notifications/mark-read')
            .then((response) => {
                if (response.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
