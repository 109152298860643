import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from './auth';
import utilities from './utilities';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import vendor from './vendor';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    vendor,
    app,
    appConfig,
    verticalMenu,
    auth,
    utilities,
  },
  strict: process.env.DEV,
});
