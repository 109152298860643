export default {
    data: [],
    total: {
        totalItems: 0,
        totalPages: 0,
        rateAverage: 0,
        rates: {
            rate_avg: 3,
            rate_count: 20,
            rates: [
                {rate: 1, rate_count: 0},
                {rate: 2, rate_count: 0},
                {rate: 3, rate_count: 0},
                {rate: 4, rate_count: 0},
                {rate: 5, rate_count: 0}
            ]
        },
    },
};
