export default {
  async get({ commit }, data) {
    commit('loadingStart', null, {root: true});

    const filters = window.mapFilterData(data);
    await this._vm.$http
      .get(`financials${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, {root: true});
        }
      })
      .catch(error => {
        commit('loadingFinish', null, {root: true});
        throw error;
      });
  },
};
