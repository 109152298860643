
export default {
    async get({commit}) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`home`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

};
