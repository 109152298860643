

window.mapFilterData= function mapFilters(data) {

    let filters = "?";

    for (const [key, value] of Object.entries(data)) {
        if (value) {
            filters += `${key}=${value}&`
        }
    }

    return filters.slice(0, -1);
};
