import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/libs/i18n';
import {localize} from 'vee-validate';
import moment from 'moment';

import store from '@/store';
import authRoutes from './routes/auth';
import middleware from "@/router/middleware/middleware";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: '/',
            redirect: `/${i18n.locale}/`,
        },
        {
            path: '/:lang',
            component: {
                render(c) {
                    return c('router-view');
                },
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    redirect: `/${i18n.locale}/dashboard`,
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/pages/dashboard/dashboard.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },

                {
                    path: 'orders',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'orders',
                            component: () => import('@/views/pages/orders/index.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },

                        {
                            path: 'detail/:id',
                            name: 'order-detail',
                            component: () => import('@/views/pages/orders/detail.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },

                    ],
                },

                {
                    path: 'categories',
                    name: 'categories',
                    component: () => import('@/views/pages/categories/index.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },

                {
                    path: 'products',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'products',
                            component: () => import('@/views/pages/products/index.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'create',
                            name: 'products-create',
                            component: () => import('@/views/pages/products/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'update/:id',
                            name: 'products-update',
                            component: () => import('@/views/pages/products/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                    ],
                },


                {
                    path: 'notification',
                    name: 'notification',
                    component: () => import('@/views/pages/dashboard/notification.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },

                {
                    path: 'discounts',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'discounts',
                            component: () => import('@/views/pages/discounts/index.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'create',
                            name: 'discounts-create',
                            component: () => import('@/views/pages/discounts/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'update/:id',
                            name: 'discounts-update',
                            component: () => import('@/views/pages/discounts/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'detail/:id',
                            name: 'discounts-detail',
                            component: () => import('@/views/pages/discounts/detail/detail.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                    ],
                },
                {
                    path: 'financial',
                    name: 'financial',
                    component: () => import('@/views/pages/financial/index.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },
                {
                    path: 'reviews',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'reviews',
                            component: () => import('@/views/pages/reviews/index.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },

                        {
                            path: ':id',
                            name: 'reviews-detail',

                            component: () => import('@/views/pages/reviews/detail.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },

                    ],
                },
                {
                    path: 'settings',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'settings',
                            component: () => import('@/views/pages/settings/settings.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'profile',
                            name: 'profile',
                            component: () => import('@/views/pages/settings/profile/profile.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },
                        {
                            path: 'work_days',
                            name: 'work_days',
                            component: () => import('@/views/pages/settings/work_days/index.vue'),
                            meta: {
                                middleware: [middleware],
                            },
                        },

                    ],
                },
                ...authRoutes
            ],
        },

        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach(async (to, from, next) => {
    const languages = ['ar', 'en'];
    if (languages.includes(to.params.lang)) {
        i18n.locale = to.params.lang;
        localize(to.params.lang);
        moment.locale(to.params.lang); // 'en'

        console.log('-----------------')
        console.log(to.params.lang)
        console.log('-----------------')
        store.commit('TOGGLE_RTL', to.params.lang);
        await store.dispatch('setCurrentLocale', to.params.lang);
        if (to.meta.middleware) {
            const middleware = Array.isArray(to.meta.middleware)
                ? to.meta.middleware
                : [to.meta.middleware];

            const context = {
                from,
                next,
                router,
                to,
            };
            const nextMiddleware = nextFactory(context, middleware, 1);

            return middleware[0]({...context, next: nextMiddleware});
        }
        return next();
    } else {
        if (to.name) next({name: to.name, params: {lang: i18n.locale}});
        else next({name: 'dashboard', params: {lang: i18n.locale}});
    }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
