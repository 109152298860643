export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    setDetail: (state, data) => {
        state.detail = data;
    },



    updateData: (state, data) => {
        if (data.is_order) {
            let temp = state.data;

            if(data.is_review){
                data.review = JSON.parse(data.review)
            }
            state.data = temp.map((item) => item.id == data.id ? {
                ...item,
                ...data
            } : item)

            if (state.detail) {
                if (state.detail.id == data.id) {
                    state.detail = {
                        ...state.detail,
                        ...data
                    }
                }
            }
        } else {
            if (!state.data.some((item) => item.id == data.id)) {
                state.data.unshift(data)
            }
        }
    },
};
