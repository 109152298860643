export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },
  getLimit(state) {
    return state.limit;
  },
};
