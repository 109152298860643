export default {


    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`work-days/create`, data)
            .then((response) => {
                if (response.status === 200) {

                    commit('setData', response.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {

                if (error.response.status === 405) {
                    commit('failMessage', 'max_work_shifts', {root: true});
                }

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async get({commit}) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .get(`work-days`)
            .then((response) => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`work-days/toggle`, data)
            .then((response) => {
                if (response.status === 200) {

                    commit('setData', response.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async delete({commit}, id) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`work-days/delete/${id}`)
            .then((response) => {
                if (response.status === 200) {

                    commit('setData', response.data.data);
                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


};
