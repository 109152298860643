export default {
    getData(state) {
        return state.data;
    },
    getTotal(state) {
        return state.total;
    },
    getUsageData(state) {
        return state.usage;
    },
    getUsageTotal(state) {
        return state.usageTotal;
    },



    getUsers(state) {
        return state.users;
    },

    getConditions(state) {
        return state.conditions;
    },


    getDetail(state) {
        return state.detail;
    },
    getProducts(state) {
        return state.products;
    },



    getDiscountInfo(state) {
        return state.discountInfo;
    },

};
