export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);
        await this._vm.$http
            .get(`orders${filters}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setData', response.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async detail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`orders/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },


    async updateStatus({commit}, data) {

        commit('loadingStart', null, {root: true});
        commit('updateData', data)

        commit('loadingFinish', null, {root: true});
    },

    async cancel({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/cancel/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async accept({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .post(`orders/accept/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
};
