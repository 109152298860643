import categories from './categories';
import home from './home';
import products from './products';
import settings from './settings';
import notifications from './notifications';
import reviews from './reviews';
import financial from './financial';
import orders from './orders';
import discounts from './discounts';


export default {
    modules: {
        home,
        products,
        categories,
        settings,
        notifications,
        orders,
        financial,
        reviews,
        discounts
    },
    namespaced: true
}
