import store from '@/store/index';
import Vue from 'vue';

export default function middleware({to, next}) {
    let loginRoutes = [
        'login',
        'signup',
        'forgot-password',
        'reset-password',
        'validate-email',
        'verify-email',
    ];
    let loginData = Vue.$cookies.get('login-data', {parseJSON: true});
    if (loginData) {
        if (loginData.remember_me) {
          const date = Date.now();
          if (loginData.exp_date <= date) {
                store.dispatch('auth/refreshToken', loginData.refresh_token).then(() => {
                    if (loginRoutes.includes(to.name))
                        return next({name: 'dashboard', params: {lang: to.params.lang}});
                    else return next();
                });
            } else {
                if (loginRoutes.includes(to.name))
                    return next({name: 'dashboard', params: {lang: to.params.lang}});
                else return next();
            }
        } else {
            if (loginRoutes.includes(to.name))
                return next({name: 'dashboard', params: {lang: to.params.lang}});
            else return next();
        }
    } else {
        if (!loginRoutes.includes(to.name))
            return next({name: 'login', params: {lang: to.params.lang}});
        else return next();
    }
}
