export default {

    async uploadFiles({commit}, data) {

        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`upload-files`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((respo) => {
                if (respo.status === 200) {
                    return respo.data.data;
                }
            })
            .catch((error) => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
