export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,
      rateAverage: data.rate_avg,
      rates: data.rates,
    };
  },

};
