export default {
    data: {
        "users": {
            "males": 0,
            "females": 0
        },
        "popular_products": [],
        "orders_data": {
            "months": [],
            "orders": []
        },
        "sales_data": {
            "months": [],
            "sales": [],
            "profits": []
        },
    },

};
