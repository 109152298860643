import profile from './profile';
import workDays from './workDays';


export default {
    modules: {
        workDays,
        profile,
    },
    namespaced: true
}
