export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,

      total_orders: data.total_orders,
      total_clients: data.total_clients,
      his_profit: data.his_profit,
      total_sales: data.total_sales,

    };
  },

};
