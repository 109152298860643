

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`categories${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`categories/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('categories/create', data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('categories/update', data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                if (error.response.status === 402) {
                    commit('failMessage', 'category_update', {root: true});
                }

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, id) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`categories/delete/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem',id);
                }
            })
            .catch(error => {
                if (error.response.status === 402) {
                    commit('failMessage', 'category_delete', {root: true});
                }
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async reorder({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`categories/reorder/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },
};
